import {format} from 'date-fns'
import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import '../styles/blog-post.css'
import {Link} from 'gatsby'
import {getBlogUrl, getBlogCategoryUrl} from '../lib/helpers'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton
} from "react-share";
import {FaTwitter} from 'react-icons/fa';
import {FaEnvelope} from 'react-icons/fa';
import {FaFacebook} from 'react-icons/fa';
import {FaReddit} from 'react-icons/fa';

function BlogPost (props) {
  const {_rawBody, publishedAt, mainImage, title, slug} = props

  const shareUrl = 'https://alessandrosegalini.com'+getBlogUrl(publishedAt, slug.current);

  return (
    <Container>
      <div className="wrapper">
        <h3><Link to="/blog/">Blog</Link></h3>
        <div id="blog-post" className="blog-post grid">

          <div className="width-12-12-m single">

            <h2>{title}</h2>

            <div className="date-tag-holder">
              <div class="date-holder">
                <span className="date">
                  {format(new Date(publishedAt), 'MMMM Do, YYYY')}
                </span>
              </div>
              {props.categories && (
                <div className="tags">
                    {props.categories.map(category => (
                      <Link className="tag" to={getBlogCategoryUrl(category.slug.current)}>{category.title}</Link>
                    ))}
                </div>
              )}
            </div>

              {mainImage && mainImage.desktopImage && mainImage.desktopImage.asset && (
                <div className="mainImage-holder">
                  <Link to={getBlogUrl(publishedAt, slug.current)}>
                  <picture>
                    <source 
                      media="(max-width: 699px)" 
                      srcset={imageUrlFor(buildImageObj(mainImage.mobileImage))
                        .width(1000)
                        .height(Math.floor((1 / 1) * 1000))
                        .auto('format')
                        .url()}
                    />
                    <source 
                      media="(min-width: 700px)" 
                      srcset={imageUrlFor(buildImageObj(mainImage.desktopImage))
                        .width(2000)
                        .height(Math.floor((1 / 2) * 2000))
                        .auto('format')
                        .url()}
                    />
                    <img
                      src={imageUrlFor(buildImageObj(mainImage.desktopImage))
                        .width(2000)
                        .height(Math.floor((1 / 2) * 2000))
                        .auto('format')
                        .url()}
                      alt={mainImage.desktopImage.alt}
                      title={mainImage.desktopImage.alt}
                    />
                  </picture>
                  </Link>
                  {mainImage.desktopImage.caption && (
                    <span className="caption">
                      {mainImage.desktopImage.caption}
                    </span>
                  )}

                </div>
              )}

              {_rawBody && (
                  <div className="body-holder">
                    {_rawBody && <PortableText blocks={_rawBody} />}
                    <div className="social-holder">
                      <EmailShareButton url={shareUrl} subject={title}><FaEnvelope /></EmailShareButton>
                      <FacebookShareButton url={shareUrl}><FaFacebook /></FacebookShareButton>
                      <RedditShareButton url={shareUrl}><FaReddit /></RedditShareButton>
                      <TwitterShareButton url={shareUrl} title={title}><FaTwitter /></TwitterShareButton>
                      
                    </div>
                  </div>
                )}

              </div>


          </div>

        </div>

    </Container>
  )
}

export default BlogPost