import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import BlogPost from '../components/blog-post'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {
  toPlainText,
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutCatsWithoutParents
} from '../lib/helpers'

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      email
      linkedInLink
      author
      keywords
      ccLink
      ccImage {
        ...SanityImage
        alt
      }
      image {
        ...SanityImage
        alt
      }
    }
    cats: allSanityCategory(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
      edges {
        node {
          title
          slug {
            current
          }
          parents {
            id
          }
        }
      }
    }
    post: sanityPost(id: {eq: $id}) {
      id
      publishedAt
      categories {
        _id
        title
        slug {
          current
        }
      }
      mainImage {
        desktopImage {
          ...SanityImage
          alt
          caption
        }
        mobileImage {
          ...SanityImage
          alt
          caption
        }
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      _rawBody(resolveReferences: {maxDepth: 5})
    }
  }
`

const BlogPostTemplate = props => {
  const {data, errors} = props

  const site = (data || {}).site

  const catNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutCatsWithoutParents)
    : []

  const post = data && data.post

  let bimage = '';

  if(post.mainImage) {
    bimage = post.mainImage.desktopImage;
  }

  //console.log('post');
  //console.log(post);

  return (
    <Layout catNodes={catNodes} title={site.title} email={site.email} footer={site.footer} instagramLink={site.instagramLink} facebookLink={site.facebookLink} twitter={site.twitter} linkedInLink={site.linkedInLink} ccImage={site.ccImage} ccLink={site.ccLink} >
      {errors && <SEO title='GraphQL Error' />}
      {post && <SEO title={post.title || 'Untitled'} author={site.author} description={toPlainText(post._rawExcerpt)} image={bimage} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {post && 
        <BlogPost {...post} />
      }
    </Layout>
  )
}

export default BlogPostTemplate